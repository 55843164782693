<template>
  <div>Shamatha List</div>
  <div class="row">
    <div class="col-md-6">
      <div class="input-group">
        <span class="input-group-text">講次</span>
        <input type="text" v-model="basedata.New_ShamathaID" />
      </div>
    </div>

    <div class="col-md-6">
      <div class="input-group">
        <span class="input-group-text">影片來源</span>
        <input type="text" v-model="basedata.New_Source" />
      </div>
    </div>
    <div class="col-md-12">
      <div class="input-group">
        <button @click="DoAdd">送出</button>
      </div>
    </div>
  </div>

  <div class="card" v-for="(v, index) in basedata.ShamathaList" :key="index">
    <div class="card-header">第 {{ v.sid }} 講</div>
    <div class="card-body">
      <h5 class="card-title">
        影片：<a :href="v.source" target="_empty">{{ v.source }}</a>
      </h5>

      <a href="#" class="btn btn-primary" @click="DoDelete(v.id)">刪除</a>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { GetShamathaList, DeleteShamatha, PostShamatha } from "../../api.js";
export default {
  name: "ShamathaList",
  setup() {
    let errorStatus = ref("");
    const basedata = reactive({
      ShamathaList: [],
      New_ShamathaID: "",
      New_Source: "",
    });

    function DoAdd() {
      var vdata = {
        sid: parseInt(basedata.New_ShamathaID),
        source: basedata.New_Source,
      };
      PostShamatha(vdata)
        .then(function (response) {
          console.log("GetListData result:", response.data);
          basedata.ShamathaList = response.data;

          GetData();
          basedata.New_ShamathaID = "";
          basedata.New_Url = "";
          basedata.New_Source = "";
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    function DoDelete(id) {
      DeleteShamatha(id)
        .then(function (response) {
          console.log("GetListData result:", response.data);
          //basedata.ShamathaList = response.data;
          GetData();

          console.log("basedata.ShamathaList:", basedata.ShamathaList);
        })
        .catch(function (error) {
          if (!error.response) {
            // network error
            errorStatus = "Error: Network Error";
          } else {
            errorStatus = error.response.data.message;
          }
        });
    }

    function GetData() {
      GetShamathaList()
        .then(function (response) {
          console.log("GetListData result:", response.data);
          basedata.ShamathaList = response.data;
          basedata.ShamathaList.sort((a, b) => (b.SID > a.SID ? 1 : -1));
          console.log("basedata.ShamathaList:", basedata.ShamathaList);
        })
        .catch(function (error) {
          if (!error.response) {
            // network error
            errorStatus = "Error: Network Error";
          } else {
            errorStatus = error.response.data.message;
          }
        });
    }

    onMounted(() => {
      GetData();
    });
    return { errorStatus, basedata, DoDelete, DoAdd, GetData };
  },
};
</script>

<style>
</style>